import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { JobsService } from '@/services/jobs/jobs.service';
import { BuildersService } from '@/services/builders/builders.service';
import { CommunitiesService } from '@/services/communities/communities.service';
import { ContactsService } from '@/services/contacts/contacts.service';
import { DataTableDirective } from 'angular-datatables';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'datatable-repair-jobs',
  templateUrl: './repair-jobs-datatable.component.html',
  styleUrls: ['./repair-jobs-datatable.component.scss']
})
export class RepairJobsDatatableComponent implements OnInit {

	@ViewChild(DataTableDirective)
  	datatableElement: DataTableDirective;

	@Input() title : string = '';
	@Input() showFooter : boolean = true;
	@Input() rowsPerPage : number = 10;
	@Input() viewRoute : string = '';
	@Input() viewText : string = 'View';
	@Input() status : string = 'all';
	@Input() showStatus : boolean = true;
	@Input() showFilters : boolean = true;

	rows = [];
	dtOptions : DataTables.Settings = {};
	showTitle : Boolean = false
	activeStatusFilter: string = ''

	jobStatuses: string[] = [
		'Active',
		'Closed',
		'Estimated',
		'Removed'
	];

	select2Options = {
		theme: "bootstrap4",
		dropdownAutoWidth: true,
		width: "100%"
	}
	
	selectStatusFilter: Array<Select2OptionData> = [
		{
			id: '',
			text: ''
		},
		{
			id: 'active',
			text: 'Active'
		},
		{
			id: 'closed',
			text: 'Closed'
		},
		{
			id: 'estimated',
			text: 'Estimated'
		},
		{
			id: 'removed',
			text: 'Removed'
		}
	]

  	constructor(
	  	private jobsService: JobsService,
		private config: NgbPopoverConfig
  	) { 
		config.placement = 'left';
    }

  	dataTablesInit() {
	    let lastPage = 0;
		let lastSearchText = "";
		this.dtOptions = {
			pagingType: 'full_numbers',
			pageLength: this.rowsPerPage,
			lengthChange: false,
			displayStart: lastPage, // Last Selected Page
			info: this.showFooter,
			paging: this.showFooter,
			scrollX: true,
			columns: [
				{ "data": "CreatedDate" },
				{ "data": "ScheduledDate" },
				{ "data": "Name" },
				{ "data": "Phone" },
				{ "data": "Email" },
				{ "data": "Location" },
				{
					"data": "Status",
					"visible": this.showStatus,
					"orderable": false
				},
				{
					"data": "Inspections",
					render: function (data, type, row) {
						return '';
					}
				},
				{
					"data": "Detail",
					render: function (data, type, row) {
						return '';
					}
				}
			],
			columnDefs: [
				{
					targets: this.showStatus ? [7, 8] : [6, 7],
					orderable: false,
					searchable: false
				}
			],
			serverSide: true,
			processing: true,
			ajax: (data: object, callback, dataTablesParameters: any) => {
				lastPage = dataTablesParameters.start;  // Note :  dataTablesParameters.start = page count * table length
				lastSearchText = 'search' in dataTablesParameters && dataTablesParameters.search != 'undefined' ? dataTablesParameters.search.value : '';

				if ( this.activeStatusFilter !== '' ){
					data['columns'][6]['search'].value = this.activeStatusFilter.toLowerCase()
				}

	  			// Get the table data
				this.jobsService.getRepairJobsDatatable(data).subscribe((result: any) => {

					result.data.forEach((row) => {
						let status = row.Status;

						// Set Default status color
						row.StatusColor = 'dark';

						switch(status) {
							case 'Active':
								row.StatusColor = 'success';
							break;
								case 'Closed':
								row.StatusColor = 'danger';
							break;
							case 'Estimated':
								row.StatusColor = 'primary';
							break;
							case 'Inactive':
								row.StatusColor = 'warning';
							break;
						}

							row['SafetyChecklistAddLink'] = `/repair-jobs/${row.ID}/safety-inspection-add`

						if (row.Inspections.length) {
							row.Inspections.sort(function (a, b) {
								const c: any = new Date(a.CreatedDate)
								const d: any = new Date(b.CreatedDate)
								return c - d
							});
						}
					});

					this.rows = result.data;
					callback({
						recordsTotal: result.recordsTotal,
						recordsFiltered: result.recordsFiltered,
						data: [] // this gets set to empty because this.rows above is rendered by angular with the relevant formatting
					});

					$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					
					setTimeout(() => {
						$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					}, 2500);
				});
			},
			order: [[0, "desc"]],
			initComplete: () => {
				let searchLabels = document.querySelectorAll('.dataTables_filter > label');

				searchLabels.forEach((label) => {
					label.setAttribute('aria-label', 'Search/Filter Table');
				});

				this.showTitle = true
			}
		};

		// console.log('dtOptions', this.dtOptions);
		setTimeout(() => {
			$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
		}, 2000);
  	}

	ngOnInit(): void {
		this.dataTablesInit()
	}

	updateStatusFilter( event: { value: string, data } ){
		const { value } = event

		this.activeStatusFilter = value

		this.datatableElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
			dtInstance.search('');
			dtInstance.columns().search('');
			dtInstance.draw();
		});
	}
}
