import { Component, Renderer2, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';

import { AlertsService } from '@/services/alerts/alerts.service';

@Component({
  selector: 'datatable-alerts',
  templateUrl: './alerts-datatable.component.html',
  styleUrls: ['./alerts-datatable.component.scss']
})


export class AlertsDatatableComponent implements AfterViewInit, OnInit {

	@ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;

	@Input() title: string = '';
	@Input() showFooter: boolean = true;
	@Input() rowsPerPage: number = 10;
	@Input() viewRoute: string = '';
	@Input() viewText: string = 'View';
	@Input() showFilters : boolean = true;

	showTitle : Boolean = false

  	rows          = []
	alertList     = []
	activeObjectTypeFilters: string = ''
	activeAlertTypeFilters: number = null
	selectFilters: Array<Select2OptionData> = [
		{
			id: 'all',
			text: 'All'
		},
		{
			id: 'BuilderJob',
			text: 'Builder Jobs'
		},
		{
			id: 'RepairJob',
			text: 'Repair Jobs'
		},
		{
			id: 'ReroofJob',
			text: 'Reroof Jobs'
		},
		{
			id: 'WarrantyJob',
			text: 'Warranty Jobs'
		},
		{
			id: 'InspectionSafety',
			text: 'Safety Inspections'
		},
		{
			id: 'InspectionDryin',
			text: 'Dryin Inspections'
		},
		{
			id: 'InspectionShingle',
			text: 'Shingle Inspections'
		},
	]
	selectTypeFilters: Array<Select2OptionData> = [
		{
			id: 'all',
			text: 'All'
		},
		{
			id: '1',
			text: 'Permit Not Finalized'
		},
		{
			id: '2',
			text: 'P O Items Short'
		},
		{
			id: '3',
			text: 'Job Unpaid'
		},
		{
			id: '4',
			text: 'Inspection Rating Too Low'
		},
		{
			id: '5',
			text: 'Step Passed'
		},
		{
			id: '6',
			text: 'GRV Not Complete'
		},
		{
			id: '7',
			text: 'ORV Not Complete'
		},
		{
			id: '8',
			text: 'Boots Not Complete'
		},
		{
			id: '9',
			text: 'Dryer Vent Not Complete'
		}
	]

  dtOptions: DataTables.Settings = {};

	@ViewChild('jobFilters')
	jobFilters: ElementRef;

	@ViewChild('alertTable')
	alertTable: ElementRef;

	select2Options = {
		theme: "bootstrap4",
		dropdownAutoWidth: true,
		width: "100%"
	}

  constructor(
		private alertsService: AlertsService,
		private renderer: Renderer2,
		private router: Router
	){}

	dataTablesInit() {
		let lastPage = 0;
		let lastSearchText = "";
		this.dtOptions = {
			pagingType: 'full_numbers',
			pageLength: this.rowsPerPage,
			lengthChange: false,
			displayStart: lastPage, // Last Selected Page
			info: this.showFooter,
			paging: this.showFooter,
			scrollX: true,
			ordering: true,
			columns: [
				{
					"data": "GeneratedDate"
				},
				{
					"data": "ObjectType",
				},
				{
					"data": "Type",
				},
				{
					"data": "Message",
				},
				{
					"data": "FullLink"
				},
			],
			columnDefs: [
				{
					targets: [ 4 ],
					orderable: false
				},
				{
					targets: [ 0 ],
					type: 'date'
				},
				{
					className: "text-center", "targets": [ 4 ]
				}
			],
			serverSide: true,
			processing: true,
			ajax: (data: object, callback, dataTablesParameters: any) => {
				lastPage = dataTablesParameters.start;  // Note :  dataTablesParameters.start = page count * table length
				lastSearchText = 'search' in dataTablesParameters && dataTablesParameters.search != 'undefined' ? dataTablesParameters.search.value : '';

				// let page: number    = null
				// let objectType: string    = ''
				// let alertType: number    = null
				const count: number = 1000000

				if ( this.activeObjectTypeFilters !== 'all' ){
					data['columns'][1]['search'].value = this.activeObjectTypeFilters
				}

				if ( this.activeAlertTypeFilters > 0 ){
					data['columns'][2]['search'].value = this.activeAlertTypeFilters.toString()
				}

				this.alertsService.getAlertsDatatable(data).subscribe((result: any) => {
					result.data.forEach(row => {
						// format date properly
						let date = new Date(row.GeneratedDate)
						row.GeneratedDate = date.toLocaleDateString()

						// Split string by capital letters to make readable
						row.Type = row.Type.split(/(?=[A-Z])/).join(" ")
						row.ObjectType = row.ObjectType.split(/(?=[A-Z])/).join(" ")


						// Do Link logic here
						// const { LinkedJob, ObjectID, ObjectType } = full
						let urlFriendlyJobType: string = ''
						row.FullLink = "";
						if ( row.LinkedJob ) {
							let inspectionType = ''

							if ( row.LinkedJob.Type )

								if ( row.LinkedJob.Type === 'BuilderJob' ) {
									urlFriendlyJobType = row.LinkedJob.Type.split(/(?=[A-Z])/).join(" ").toLowerCase().replace( ' ', '-' ) + 's'
								}
								else {
									urlFriendlyJobType = `${row.LinkedJob.Type.toLowerCase()}-jobs`
								}

							switch( row.ObjectType ){
								case 'Inspection Safety':
									inspectionType = 'safety'
									break
								case 'Inspection Dry In':
									inspectionType = 'dryin'
									break
								case 'Inspection Shingle':
									inspectionType = 'shingle'
									break
							}
							row.FullLink = `/${ urlFriendlyJobType }/${ row.LinkedJob.ID }/${ inspectionType }-inspection/${ row.ObjectID }`
						}
						else {
							urlFriendlyJobType = row.ObjectType.toLowerCase().replace( ' ', '-' ) + 's'

							row.FullLink = `/${ urlFriendlyJobType }/${ row.ObjectID }`
						}

						return row;
					});

					this.rows = result.data;

					callback({
						recordsTotal: result.recordsTotal,
						recordsFiltered: result.recordsFiltered,
						data: [] // this gets set to empty because this.rows above is rendered by angular with the relevant formatting
					});
					
					$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					
					setTimeout(() => {
						$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					}, 2500);
				})
			},
			order: [
				[ 0, "desc" ]
			],

			initComplete: () => {
				let searchLabels = document.querySelectorAll('.dataTables_filter > label')

				searchLabels.forEach(( label ) => {
					label.setAttribute('aria-label', 'Search/Filter Table')
				})

				this.showTitle = true
			}
		}
	}

  ngOnInit(): void {
		this.dataTablesInit()
	}

	ngAfterViewInit(): void {
		this.renderer.listen( 'document', 'click', (event) => {
			if ( event.target.hasAttribute( "job-link" )) {
				event.preventDefault()
        this.router.navigate([ event.target.getAttribute( "job-link" ) ])
      }
		});
	}

	updateObjectType( event: { value: string, data } ){
		const { value } = event

		this.activeObjectTypeFilters = value

		this.datatableElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
			dtInstance.search('');
			dtInstance.columns().search('');
			dtInstance.draw();
		});
	}

	updateType( event: { value: number, data } ){
		const { value } = event

		this.activeAlertTypeFilters = value

		this.datatableElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
			dtInstance.search('');
			dtInstance.columns().search('');

			dtInstance.draw();
		});
	}
}
